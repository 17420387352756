import React, { useState, useEffect, useContext } from "react"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from '../components/seo'
import { graphql, Link } from "gatsby"
import {
  Container,
  SwiperContainer,
  Wrapper,
  Header,
  HeaderTitle,
  Like,
  Produktbeschreibung,
  ConfigContainer,
  Right,
  CallToActionContainer,
  DetailsLi,
  Price,
  InnerModalContainer,
  ConfigUL,
  ConfigLi,
  GeneralDetailsContainer,
  GeneralDetailsLabel,
  GeneralDetailsWrapper,
  GeneralDetailsUl,
  MoodContainer

} from "../styles/templates/generalStyles"
import Heart from "../components/heart"
import FilledHeart from "../components/filledHeart"
import { FavContext } from '../context/FavContext'

import Modal from "../components/Modal"
// import FormModal from "../components/form-modal"
// import SingleProductForm from "../components/utilities/singleProductForm"
import SuperSwiper from "../components/Swiper"





const Template = ({ data, props }) => {
  const item = data.allWpStuhl.nodes[0]
  console.log(item)

  // hasVAriants?

  const { favs, addFav, removeFav } = useContext(FavContext)

  const [isFav, setIsFav] = useState(false)
  const [added, setAdded] = useState(false)


  const itemSlides = item.postTypeStuhlFields.slides;
  const itemSlidesLf = itemSlides.map(slide => slide.localFile);
  const itemSlideCIS = itemSlidesLf.map(slide => slide.childImageSharp);
  const itemSlidesFluid = itemSlideCIS.map(slide => slide.fluid);
  const altText = itemSlides.map(slide => slide.altText);

  const moebel = item.tags.nodes.map(node => node.name)
  const variant = item.ausfuehrungen.nodes.map(node => node.name)

  const itemId = item.id
  const itemSlug = `/stuehle/${item.slug}`
  const itemName = moebel[0]
  const itemPrice = item.postTypeStuhlFields.price
  const itemVariant = variant[0]
  const itemImg = itemSlidesFluid[0]

  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false);
      }, 1500)
    }
  }, [added])

  useEffect(() => {
    if (favs.some(fav => fav.id === itemId)) {
      setIsFav(true)
    }
  }, [isFav, favs, itemId])

  const handleAdd = (e) => {
    e.preventDefault();
    addFav(
      itemImg,
      itemName,
      itemVariant,
      itemPrice,
      itemSlug,
      itemId)
      .then(() => {
        setIsFav(true)
        setAdded(true);
      })
  }

  const handleRemove = (e) => {
    e.preventDefault();
    removeFav(itemId)
      .then(() => {
        setIsFav(false)
      })
  }

  return (
    <Layout>
      <SEO title={variant + " " + moebel}></SEO>
      <Container>
        <Wrapper>
          <SwiperContainer>
            <SuperSwiper
              alt={altText}
              slideData={itemSlides}
            />
          </SwiperContainer>
          <Right>
            <div>
            <Header>

                <HeaderTitle>{moebel}</HeaderTitle>

            </Header>

            <Produktbeschreibung>
              {item.postTypeStuhlFields.description}
            </Produktbeschreibung>
            </div>

            <ConfigContainer>
              <Modal name="Varianten">
                <InnerModalContainer>
                  <ConfigUL>
                    {item.postTypeStuhlFields.variants === null ?
                      <> <div
                      style={{width: "100%"}}></div> </> : 
                          <>
                          {item.postTypeStuhlFields.variants.map(m =>
                            <ConfigLi>
                              <Link activeStyle={{ color: "blue", fontSize: "1.2rem" }} to={`/stuehle/${m.link.slug}`}>
                                <Img fluid={m.img.localFile.childImageSharp.fluid} />
                                <h5 style={{ marginTop: "10px", textAlign: "center" }}>{m.variant}</h5>
                              </Link>
                            </ConfigLi>
                          )}  
                      </>
                    }
                  </ConfigUL>
                </InnerModalContainer>
              </Modal>
            </ConfigContainer>

            <GeneralDetailsContainer>
              <GeneralDetailsWrapper>
                <GeneralDetailsLabel>Abmessungen</GeneralDetailsLabel>
                <GeneralDetailsUl>
                  {item.postTypeStuhlFields.dimensions.map(i =>
                    <DetailsLi>
                      {` ${i.dimension}`}: {` ${i.value} cm`}
         
              </DetailsLi>
                  )}
                </GeneralDetailsUl>
              </GeneralDetailsWrapper>

              <GeneralDetailsWrapper>
                <GeneralDetailsLabel>Materialien</GeneralDetailsLabel>
                <GeneralDetailsUl>
                  {item.postTypeStuhlFields.materials.map(i =>
                    <DetailsLi>
                      {i.materialbeschreibung}
                    </DetailsLi>
                  )}
                </GeneralDetailsUl>
              </GeneralDetailsWrapper>

            </GeneralDetailsContainer>

            <CallToActionContainer>
            {isFav === false &&
                <form onSubmit={handleAdd} >
                  <Like type="submit">
                    <Heart />
                  </Like>
                </form>
              }

              {isFav === true &&
                <form onSubmit={handleRemove} >
                  <Like type="submit">
                    <FilledHeart />
                  </Like>
                </form>
              }
              <Price>Ab {item.postTypeStuhlFields.price} €</Price>
            </CallToActionContainer>
          </Right>
        </Wrapper>




        {/* <MoodContainer>
        
        <MoodImg src={sofa.SofaOhneBeine.mood.localFile.url} /> 
        <Produktbeschreibung>
              {sofa.SofaOhneBeine.produktbeschreibung}
            
        </Produktbeschreibung>
      </MoodContainer> */}

{item.postTypeStuhlFields.moodimage &&
          <MoodContainer>
            <Img fluid={item.postTypeStuhlFields.moodimage.localFile.childImageSharp.fluid} />
          </MoodContainer>
        }


      </Container>

    


    </Layout>
  )
}

export default Template

export const query = graphql`
query($slug: String!) {
    allWpStuhl(filter: {slug: {eq: $slug}}) {
      nodes {
        id
        slug
        seo {
          title
        }
        ausfuehrungen {
          nodes {
            name
          }
        }
        tags {
          nodes {
            name
          }
        }
        postTypeStuhlFields {
          description
          price
          dimensions {
            dimension
            value
          }
          materials {
            materialbeschreibung
          }
          slides {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, maxHeight: 750, quality: 99, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          variants {
            img {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            
            link {
              ... on WpStuhl {
                id
                slug
              }
            }
            variant
          }
          moodimage {
          localFile {
            childImageSharp {
              fluid(quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        }
      }
    }
}
`