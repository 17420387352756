import React from "react"

const Close = () => {
    return (
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 26 25.89" 
        style={{
            fill:"#fff", 
            stroke:"#000", 
            strokeLinecap: "round", 
            strokeMiterlimit: 10,
            strokeWidth: "2px",
            width: "18px",
            height: "28px"
            }}>
            <line class="cls-1" x1="1" y1="1" x2="25" y2="24.89" />
            <line class="cls-1" x1="25" y1="1" x2="1" y2="24.89" />
        </svg>
    );
}

export default Close;

